import liff from "@line/liff";
import { SocialLineLoginModule } from "@/api/social/line/login";
import JwtService from "@/core/services/JwtService";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import store from "@/store";

export function socialLineLoginLiff() {
  if (process.env.NODE_ENV === "production") {
    const access_token = liff.getAccessToken();
    SocialLineLoginModule.post({ access_token: access_token }).then(
      async (response) => {
        JwtService.saveToken(response.data.token);
        await store.dispatch(Actions.VERIFY_AUTH, {
          token: JwtService.getToken(),
        });
      }
    );
  }
}
export async function initializeLiff() {
  if (process.env.NODE_ENV === "production") {
    try {
      await liff.init({
        liffId: "2006156638-V42qjrYe",
      }); // 替換為你的 LIFF ID
      if (liff.isLoggedIn()) {
        socialLineLoginLiff();
      } else {
        liff.login();
      }
    } catch (error) {
      alert(`LIFF initialization failed: ${error}`);
    }
  }
}

export default liff;
